import React, { useState } from "react"
import Layout from "../../components/common/Layout"
import Section from "../../components/common/Section"
import Title from "../../components/common/Title"
import styled from "styled-components"
import fetch from "node-fetch"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export default function Form() {
  const [loading, setLoading] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const formatData = (data, token) => {
    const name = data[0]
    const phone = data[1]
    const email = data[2]

    return {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name[1],
        phone: phone[1],
        email: email[1],
        token: token,
      }),
    }
  }

  const submitForm = async e => {
    e.preventDefault()

    setLoading(true)

    if (!executeRecaptcha) {
      setLoading(false)
      return
    }

    const result = await executeRecaptcha("form_page")
    const data = new FormData(e.target)
    const formData = [...data.entries()]
    const request = formatData(formData, result)
    const url =
      "https://cm5s34yjb7.execute-api.us-east-1.amazonaws.com/default/generateLead"

    const fetchResponse = await fetch(url, request)
    const response = await fetchResponse.json()

    if (fetchResponse.ok) {
      navigate("/form/success")
    } else {
      setLoading(false)
      if (response.msg) alert("Error message: " + response.msg)
      else alert("Error message: " + response)
    }
  }
  return (
    <Layout>
      <Section>
        <StyledForm onSubmit={submitForm}>
          <Title text="Book a consultation" sub="It's  completely free" />
          <div className="input-row">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Here"
              required
            />
          </div>
          <div className="input-row">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              placeholder="Here"
              required
            />
          </div>
          <div className="input-row">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Here"
              required
            />
          </div>
          {!loading && <input type="submit" value="Book" />}
          {loading && <Spinner />}
          <p className="recap">
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
            <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
            apply.
          </p>
        </StyledForm>
      </Section>
    </Layout>
  )
}

const Spinner = styled.div`
  margin-inline: auto;
  display: inline-block;
  width: 80px;
  height: 80px;

  :after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const StyledForm = styled.form`
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .recap {
    margin-top: -2rem;
    font-size: 11px;
    a {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  input[type="submit"] {
    position: relative;
    outline: none;
    border: none;

    font-weight: 400;
    font-size: var(--p-size);

    background-color: rgba(255, 255, 255, 0.05);
    color: var(--secondary-color);
    padding-block: 1.5rem;
    width: 100%;
    border-radius: var(--border-radius);
    transition: 100ms;

    @media (hover: hover) {
      :hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        font-weight: 500;
      }
    }
    cursor: pointer;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    width: 100%;
    border-radius: var(--border-radius);
    padding-inline: 1rem;
    padding-block: 1.5rem;
    outline: none;
    border: none;
    font-size: var(--p-size);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  label {
    display: block;
    margin-bottom: 1rem;
    font-size: var(--p-size);
  }
`
export function Head() {
  return <title>The Kitchen Guy</title>
}
