import React from "react"
import GlobalStyle from "../../style/GlobalStyle"
import styled from "styled-components"
const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
      <Footer>&copy; 2022 The Kitchen Guy</Footer>
    </>
  )
}

const Footer = styled.div`
  height: 10rem;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`

export default Layout
