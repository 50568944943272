import React from "react"
import styled from "styled-components"

const Title = ({ text, sub }) => {
  return (
    <Wrapper>
      <h1>{text}</h1>
      <h2>{sub}</h2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 2rem;

  h1 {
    margin-bottom: 0.5rem;
  }
`

export default Title
