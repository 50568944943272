import React from "react"
import { createGlobalStyle } from "styled-components"

import "normalize.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"

const GlobalStyle = () => {
  return <Style />
}

const Style = createGlobalStyle`
    :root{
        --primary-color: #0B0B0B;
        --secondary-color: #F8F8F8;
        --max-width: 800px;
        --line-thickness: 0.8px;
        --border-radius: 10px;
        --p-size: clamp(18px, -0.875rem + 8.333vw, 22px);


    }
    html{
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }
    h1,
    h2,
    h3,
    p,
    body{
        margin:0;
        padding: 0;
    }

    body{
        font-family: "Inter", sans-serif; 

    }

    p{
        font-weight: 300;
        font-size: var(--p-size);
        line-height: clamp(29px, -0.875rem + 8.333vw, 33px);
    }

    h1{
        font-weight: 600;
        font-size: clamp(36px, -0.875rem + 8.333vw, 40px);
    }
    h2{
        font-weight: 300;
        font-size: clamp(24px, -0.875rem + 8.333vw, 28px);
        line-height: 45px;

    }
    h3{
        font-weight: 500;
        font-size: clamp(1.3rem, -0.875rem + 8.333vw, 1.5rem);
    }

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }

    .grecaptcha-badge { visibility: hidden; }



`

export default GlobalStyle
