import React from "react"
import styled from "styled-components"

const Section = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.section`
  padding-top: 3rem;
  width: 90%;
  max-width: var(--max-width);
  margin-inline: auto;

  @media screen and (min-width: 760px) {
    padding-top: 7rem;
  }
`
export default Section
